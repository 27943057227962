import { createSlice } from "@reduxjs/toolkit";

// Initialize favoriteList from localStorage
const storedFavoriteList =
    localStorage.getItem("favoriteList") !== null
        ? JSON.parse(localStorage.getItem("favoriteList"))
        : [];

const initialState = {
  favoriteList: storedFavoriteList,
};

export const favoriteSlice = createSlice({
  name: "favorite",
  initialState,
  reducers: {
    addToFavorites: (state, action) => {
      const productToAdd = action.payload;
      const productExists = state.favoriteList.find(
          (item) => item.productId === productToAdd.productId
      );
      if (!productExists) {
        state.favoriteList.push(productToAdd);
      }
    },
    removeFromFavorites: (state, action) => {
      const productToRemove = action.payload;
      state.favoriteList = state.favoriteList.filter(
          (item) => item.productId !== productToRemove.productId
      );
    },
    clearFavorites: (state) => {
      state.favoriteList = [];
    },
    setFavoriteItems: (state, action) => {
      // Update the favorite list with new data
      state.favoriteList = action.payload;
    },
  },
});

// Middleware to sync favoriteList with localStorage
export const favoriteMiddleware = (store) => (next) => (action) => {
  const result = next(action);
  if (action.type?.startsWith("favorite/")) {
    const favoriteList = store.getState().favorite.favoriteList;
    if (action.type === "favorite/clearFavorites") {
      localStorage.removeItem("favoriteList");
    } else {
      localStorage.setItem("favoriteList", JSON.stringify(favoriteList));
    }
  }
  return result;
};

export const { addToFavorites, removeFromFavorites, clearFavorites, setFavoriteItems } = favoriteSlice.actions;

export default favoriteSlice.reducer;
