import React, { useEffect, useState } from "react";
import "./SecondNavbar.css";
import logoImage from "../../assets/logos/logo_xl.png";
import {FaCreditCard, FaHome, FaInfoCircle} from "react-icons/fa";
import {IoIosMail} from "react-icons/io";


const SecondNavbar = () => {
  const [expand, setExpand] = useState(false);


  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
      <>
        <div className="banner-outer">
          <div className="banner-inner responsive-wrapper">
            <p>
              💥 Profitez de Dr Deal gratuitement pendant son développement et faites des économies dès maintenant ! 💸
            </p>
          </div>
        </div>
        <div className="w-full px-4 text-gray-700 bg-white  newNavbar">
          <div
              className="container flex flex-col flex-wrap items-baseline justify-between py-1 px-0 md:flex-row max-w-7xl">
            <div className="flex items-center justify-between w-full md:w-auto">
              <a href="/"
                 className="textLogo text-xl font-black leading-none text-gray-900 select-none navbarBtnLink navbarLogo">

                <img src={logoImage} alt="logo" className="NavBarLogoSmall"/>

              </a>
              <button
                  onClick={toggleMenu}
                  className="navbarToggleBtn inline-flex items-center justify-center p-2 text-gray-700 rounded-md md:hidden focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
              >
                <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d={!isOpen ? "M4 6h16M4 12h16m-7 6h7" : "M6 18L18 6M6 6l12 12"}
                  />
                </svg>
              </button>
            </div>

            <div
                className={`${
                    isOpen ? 'block' : 'hidden'
                } md:flex md:flex-row md:items-center w-full md:w-auto mt-4 md:mt-0 md:items-baseline newNavbBarLinks`}
            >
              <nav className="flex flex-col md:flex-row   md:border-gray-200">
                <a href="/" className="mr-5 leading-6 text-gray-600 hover:text-gray-900">
                  Accueil <FaHome/>

                </a>
                <a href="/contact" className="mr-5 leading-6 text-gray-600 hover:text-gray-900 ">
                  Contactez-nous <IoIosMail/>

                </a>
                <a href="#_" className="mr-5 leading-6 text-gray-600 hover:text-gray-900 ">
                  A propos <FaInfoCircle/>

                </a>
                <a href="#_" className="mr-5 leading-6 text-gray-600 hover:text-gray-900 ">
                  Tarifs <FaCreditCard/>

                </a>
              </nav>

              <div className="flex flex-col  space-x-0 justify-between md:flex-row md:space-x-6 md:mt-0 navbarBtnLinks">
                <a
                    href="/login"
                    target="_blank"
                    className="navbarBtnLink inline-flex font-medium  items-center justify-center px-4 py-2 mt-2 text-base leading-6 text-white whitespace-no-wrap bg-sky-500 border border-transparent rounded-md shadow-sm md:mt-0 hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                >
                  Se connecter
                </a>
                <a
                    href="/register"
                    target="_blank"
                    className="navbarBtnLink inline-flex font-medium  items-center justify-center px-4 py-2 mt-2 text-base leading-6 text-white whitespace-no-wrap bg-sky-500 border border-transparent rounded-md shadow-sm md:mt-0 hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                >
                  S'inscrire
                </a>
              </div>
            </div>
          </div>
        </div>


        {/*<Navbar expand="md" className={"navbar"} collapseOnSelect expanded={expand} key={'navbarKey'}>
          <Container>
            <Navbar.Brand as={Link} to="/">
              <ion-icon name="planet-outline"></ion-icon>
              <h1 className="logo">
                Dr<span>Fouine</span>
              </h1>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpand(!expand)} />

            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
              <Nav className="ms-auto d-flex justify-content-start align-items-center">
                <Nav.Link
                    as={Link}
                    to="/"
                    onClick={() => setExpand(false)}
                    className={"d-flex align-items-center justify-center gap-1"}
                >
                  Accueil
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                       className="bi bi-house-door text-gray-800 dark:text-white" viewBox="0 0 16 16">
                    <path
                        d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4z"/>
                  </svg>
                </Nav.Link>
                <Nav.Link
                    as={Link}
                    to="/contact"
                    onClick={() => setExpand(false)}
                    className={"d-flex align-items-center"}
                >
                  Contact
                  <svg
                      className="w-6 h-6 text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                  >
                    <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m12 18-7 3 7-18 7 18-7-3Zm0 0v-5"
                    />
                  </svg>
                </Nav.Link>
                <a href="/shop" target="_blank" rel="noopener noreferrer"
                   className="btn-visit-comparator flex justify-center align-items-center gap-1">
                  Visiter le comparateur
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                       className="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                    <path fillRule="evenodd"
                          d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5"/>
                    <path fillRule="evenodd"
                          d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z"/>
                  </svg>
                </a>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>*/}
      </>
  );
};

export default SecondNavbar;
