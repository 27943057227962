import ReactGA from 'react-ga';

const TRACKING_ID = 'UA-XXXXXXXXX-X'; 
ReactGA.initialize(TRACKING_ID);

export const logPageView = () => {
    ReactGA.pageview(window.location.pathname + window.location.search);
};

export default ReactGA;
